
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import DetailContrat from "@/views/still/contrats/DetailContrat.vue";
import ImportContrat from "@/views/still/contrats/ImportContrat.vue";
import DetailEquipement from "@/views/still/contrats/DetailEquipement.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";


export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    DetailContrat,
    DetailEquipement,
    ImportContrat,
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    //moment.default.locale("fr");
    const router = useRouter();
    const { t } = useI18n()

    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      drawerImport: ref(false),
      drawerDetail: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      equipementSelect: {},
      contratSelect: {},
      tableFilters: [] as any,
      applyFilterCode : 0,
    });

    const tableHeader = ref([
      {
        name: "Gamme",
        key: "constructeur",
        sortable: true,
      },
      {
        name: "Produit",
        key: "fsh_contract_number",
        sortable: true,
      },
      {
        name: "Quantité",
        key: "total",
        sortable: true,
      },
      {
        name: "Commandes",
        key: "xliens",
        sortable: true,
      },
      {
        name: "Contrats",
        key: "contrats_liens",
        sortable: true,
      },
      {
        name: "Actions",
        key: "code",
        sortable: true,
      },
    ]);

/*
    const tableFilters = ref([
      { name: "Constructeur", key: "constructeur", label: "constructeur", },
      { name: "Type de produit", key: "fsh_product_family", label: "fsh_product_family", },
    ]);
*/
    const ExcelFormat = ref({
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const detailContrat = (async (numContrat) => {
      let myList = await mAxiosApi.prototype.getAxios("/getContratSupport/"+numContrat);
      state.contratSelect = myList.results[0];
      state.drawer = true;
    });

    const affDetail = async (data) => {
      // console.log('Detail',data);
      state.equipementSelect = data;
      state.drawerDetail = true;
    };

    const affSUivant = async () => {
      if (posIndex()<state.myList.length) {
        state.contratSelect = state.myList[posIndex()+1];
        // await affDetail(state.myList[posIndex()+1]);
      }
    };

    const affPrecedent = async () => {
      if (posIndex()>0) {
        state.contratSelect = state.myList[posIndex()-1];
        // await affDetail(state.myList[posIndex()-1]);
      }
    };

    const posIndex = () => {
      const mlContrat: any = state.myList;
      const indexCont = mlContrat.findIndex(function (data: any) {
        const mContrat: any = state.contratSelect;
        return data.fsh_contract_number == mContrat.fsh_contract_number;
      });
      return indexCont;
    };

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    const translateCommande = (list) => {
      // console.log(list)
      // const htmlObject:any = document.createElement("div")
      // htmlObject.innerHTML = list
      // let text = htmlObject.firstChild?.firstChild?.innerText
      // if (text.indexOf("Commande") === 0) { 
      //   const translated = text.split("Commande")
      //   htmlObject.firstChild.firstChild.innerText = t("Commande") + translated[1]
      //   return htmlObject.innerHTML
      // }else{
      //   return list
      // }
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Vos équipements sous contrat de support", []);

      let myList = await mAxiosApi.prototype.getAxios("/getEquipementContrat");
      
      myList.results.forEach(
        function(item: any){
          item.contrats_liens = JSON.parse(item.contrats_liens);
          item.serials = JSON.parse(item.serials);
          //console.log(item.contrats_liens);
        }
      );

      state.initialMyList = myList.results;
      state.myList = myList.results;      
      //console.log(state.myList);
      state.loaderEnabled = false;

      state.tableFilters = [
        { name: "Constructeur", key: "constructeur", label: "constructeur", },
        { name: "Type de produit", key: "fsh_product_family", label: "fsh_product_family", default: null },
      ];


    });

    onUpdated(async () => {
      if (state.applyFilterCode == 0 && router.currentRoute.value.params.code) {
        const typeProd = router.currentRoute.value.params.code as string;
        state.tableFilters = [
          { name: "Constructeur", key: "constructeur", label: "constructeur", },
          { name: "Type de produit", key: "fsh_product_family", label: "fsh_product_family", default: [typeProd == 'Gamme inconnue' ? '#NA' : typeProd] },
        ];
        state.applyFilterCode = 1;
      }
    });


    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      ExcelFormat,
      imgConst,
      affSUivant,
      affPrecedent,
      posIndex,
      refreshRouter,
      detailContrat,
      translateCommande,
    };
  },
});
