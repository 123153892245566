<template>
<div>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>

  <transition name="fade">
        <div class="card" v-if="!state.loaderEnabled">
          <div class="card-header border-0 pt-6">
            <button type="button" class="btn btn-sm btn-text-icon btn-primary btn-active-light m-1" style="margin-top: 9px !important; height: 44px;" @click="state.drawerImport = true">
              <span class="svg-icon svg-icon-1 svg-icon-dark">
                <inline-svg src="media/icons/duotune/files/fil022.svg" />
              </span>
              <span >
                {{$t("Importer des contrats / Equipements")}}
              </span>
            </button>
            <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="state.tableFilters"
                :key="state.tableFilters"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                ExcelFilename="Stillnetwork_Equipement"
                widthDecalage="300"
                :ExcelFormat="ExcelFormat"
            ></SearchAndFilter>
          </div>
          <div class="card-body pt-0">
            <div v-if="state.myList.length == 0"  class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10">
              <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                <inline-svg src="media/icons/duotune/general/gen048.svg" />
              </span>
              <div class="d-flex flex-column">
                <h4 class="mb-1 text-dark">{{$t("Aucun Contrat")}}</h4>
                <span
                  >{{$t("Il n'existe aucun contrat à afficher correspondant à vos critères de recherche.")}}</span
                >
              </div>
            </div>
            <Datatable
              v-if="state.myList && state.myList.length > 0"
              :table-data="state.myList"
              :table-header="tableHeader"
              :enable-items-per-page-dropdown="true"
              :empty-table-text="$t('Aucun contrat à afficher.')"
              :key="state.loadingDatatable"
            >
              <template v-slot:cell-constructeur="{ row: data }">
                <div style="margin-left: 15px" class="text-gray-800 w-200px">
                  <img :src="imgConst(data.fsh_knum_constructeur)"  v-if="imgConst(data.fsh_knum_constructeur) && data.fsh_knum_constructeur" alt="image" style="width: auto; max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;" />
                  <div v-else class="badge text-gray-900" style="width: auto; min-height: 40px; min-width:75px; background-color: #ececec; float: left;margin-right:10px;" >{{ data.constructeur }}</div>
                </div>
                <div class="text-gray-500">
                    <span class="fs-6 text-dark text-bolder" style='margin-left:3px; font-weight: 600;'>
                      <span v-if="data.fsh_product_family!='#NA'">{{ data.fsh_product_family }}</span>
                      <span v-else><i> </i></span>
                    </span>
                    <div class="fs-6 ">&nbsp;{{ data.fsh_item_type }}</div>
                </div>
              </template>

              <template v-slot:cell-fsh_contract_number="{ row: data }">
                <div class="text-gray-500">
                    <span class="fs-6 text-dark" style='margin-left:3px;'>{{ data.fsh_product_number }}</span>
                    <div class="fs-7 text-gray-500">&nbsp;{{ data.fsh_product_description }}</div>
                </div>
              </template>


              <template v-slot:cell-total="{ row: data }">
                <div class="d-flex flex-column align-items-center gap-2">
                  <div class="text-danger fs-3 badge badge-white text-dark w-100px">
                      {{ data.total }}
                  </div>
                  <div v-if="data.futur_expiration > 0" class="fs-7 badge badge-light-warning text-gray-800 mt-2">
                      {{ data.futur_expiration }} <span style="font-weight: 400">{{$t("expiration(s) avant M-3")}}</span>
                  </div>
                  <div v-if="data.expire > 0" class="fs-7 badge badge-light-danger">
                      {{ data.expire + ' ' + $t("expiré(s)") }}
                  </div>
                </div>
              </template>

              <template v-slot:cell-xliens="{ row: data }">
                <el-dropdown v-if="data.xlienstotal>0">
                  <span class="el-dropdown-link">
                    <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark w-175px">
                      {{ data.xlienstotal }} {{$t("commande")}}<span v-if="data.xlienstotal>1">{{$t("s")}}</span>
                      &nbsp;<span class="svg-icon svg-icon-1"> <inline-svg src="media/icons/duotune/\arrows/arr072.svg"/> </span>
                    </a>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu v-html="data.xliens">
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <span v-else class="text-danger fs-7 badge badge-light-danger">
                  {{$t("Aucune commande liée")}}
                  </span>
              </template>
              
              <template v-slot:cell-contrats_liens="{ row: data }">
                <el-dropdown v-if="data.xcontratstotal>1">
                  <span class="el-dropdown-link">
                    <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark w-175px">
                      {{ data.xcontratstotal }} {{$t("contrat")}}<span v-if="data.xcontratstotal>1">{{$t("s")}}</span>
                      &nbsp;<span class="svg-icon svg-icon-1"> <inline-svg src="media/icons/duotune/\arrows/arr072.svg"/> </span>
                    </a>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                        <li class="el-dropdown-menu__item" aria-disabled="false" tabindex="-1" v-for='contrat in data.contrats_liens' :key='contrat'  >
                          <a class="text-gray-800" @click='detailContrat(contrat)'><span class="text-gray-700">{{$t("Contrat N°")}} </span> <b>{{ contrat }}</b></a>
                        </li>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <span v-else-if="data.xcontratstotal==1">
                  <span class="el-dropdown-link">
                    <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark w-175px" @click='detailContrat(data.contrats_liens[0])'>
                      <span class="text-gray-700">{{$t("N°")}}</span> <b>{{ data.contrats_liens[0] }}</b>
                    </a>
                  </span>
                </span>
                <span v-else class="text-danger fs-7 badge badge-light-danger">
                  {{$t("Aucune contrat lié")}}
                </span>
                
              </template>
              <template v-slot:cell-code="{ row: data }">
                <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" @click='affDetail(data)'>
                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                    <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                    {{$t("Détail du produit")}}
                </a>
              </template>
            </Datatable>

          </div>

        </div>
    
  </transition>
  <el-drawer v-model="state.drawer" :with-header="false" size="100%" modal-class="modal-transp" v-show="state.contratSelect.fsh_contract_number">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Détail de votre contrat")}} {{ state.contratSelect.constructeur }} {{$t("N°")}} {{ state.contratSelect.fsh_contract_number }}
          </h1>
        </div>
      </div>
      <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
          <DetailContrat :zContrat="state.contratSelect" :key="state.contratSelect.fsh_contract_number" card-classes="mb-12"></DetailContrat>
      </div>
    </div>
  </el-drawer>
  <el-drawer v-model="state.drawerDetail" :with-header="false" size="100%" modal-class="modal-transp">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawerDetail = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Détail de l'équipement")}} {{ state.equipementSelect.constructeur }} <span class='text-gray-500'>&nbsp;&nbsp;{{ state.equipementSelect.fsh_product_number }}</span>&nbsp;
          </h1>
        </div>
      </div>
      <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
          <DetailEquipement :zEquipement="state.equipementSelect" :key="state.equipementSelect.fsh_product_number" card-classes="mb-12"></DetailEquipement>
      </div>
    </div>
  </el-drawer>
  <el-drawer direction='ttb' v-model="state.drawerImport" :with-header="false" size="100%" modal-class="modal-transp">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawerImport = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Annuler l'import")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Importer des contrat de support")}}
          </h1>
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body" style="    overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
        <ImportContrat v-if="state.drawerImport" @refreshRouter="refreshRouter($event)" card-classes="mb-12"></ImportContrat>
      </div>
    </div>
  </el-drawer>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import DetailContrat from "@/views/still/contrats/DetailContrat.vue";
import ImportContrat from "@/views/still/contrats/ImportContrat.vue";
import DetailEquipement from "@/views/still/contrats/DetailEquipement.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";


export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    DetailContrat,
    DetailEquipement,
    ImportContrat,
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    //moment.default.locale("fr");
    const router = useRouter();
    const { t } = useI18n()

    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      drawerImport: ref(false),
      drawerDetail: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      equipementSelect: {},
      contratSelect: {},
      tableFilters: [] as any,
      applyFilterCode : 0,
    });

    const tableHeader = ref([
      {
        name: "Gamme",
        key: "constructeur",
        sortable: true,
      },
      {
        name: "Produit",
        key: "fsh_contract_number",
        sortable: true,
      },
      {
        name: "Quantité",
        key: "total",
        sortable: true,
      },
      {
        name: "Commandes",
        key: "xliens",
        sortable: true,
      },
      {
        name: "Contrats",
        key: "contrats_liens",
        sortable: true,
      },
      {
        name: "Actions",
        key: "code",
        sortable: true,
      },
    ]);

/*
    const tableFilters = ref([
      { name: "Constructeur", key: "constructeur", label: "constructeur", },
      { name: "Type de produit", key: "fsh_product_family", label: "fsh_product_family", },
    ]);
*/
    const ExcelFormat = ref({
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const detailContrat = (async (numContrat) => {
      let myList = await mAxiosApi.prototype.getAxios("/getContratSupport/"+numContrat);
      state.contratSelect = myList.results[0];
      state.drawer = true;
    });

    const affDetail = async (data) => {
      // console.log('Detail',data);
      state.equipementSelect = data;
      state.drawerDetail = true;
    };

    const affSUivant = async () => {
      if (posIndex()<state.myList.length) {
        state.contratSelect = state.myList[posIndex()+1];
        // await affDetail(state.myList[posIndex()+1]);
      }
    };

    const affPrecedent = async () => {
      if (posIndex()>0) {
        state.contratSelect = state.myList[posIndex()-1];
        // await affDetail(state.myList[posIndex()-1]);
      }
    };

    const posIndex = () => {
      const mlContrat: any = state.myList;
      const indexCont = mlContrat.findIndex(function (data: any) {
        const mContrat: any = state.contratSelect;
        return data.fsh_contract_number == mContrat.fsh_contract_number;
      });
      return indexCont;
    };

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    const translateCommande = (list) => {
      // console.log(list)
      // const htmlObject:any = document.createElement("div")
      // htmlObject.innerHTML = list
      // let text = htmlObject.firstChild?.firstChild?.innerText
      // if (text.indexOf("Commande") === 0) { 
      //   const translated = text.split("Commande")
      //   htmlObject.firstChild.firstChild.innerText = t("Commande") + translated[1]
      //   return htmlObject.innerHTML
      // }else{
      //   return list
      // }
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Vos équipements sous contrat de support", []);

      let myList = await mAxiosApi.prototype.getAxios("/getEquipementContrat");
      
      myList.results.forEach(
        function(item: any){
          item.contrats_liens = JSON.parse(item.contrats_liens);
          item.serials = JSON.parse(item.serials);
          //console.log(item.contrats_liens);
        }
      );

      state.initialMyList = myList.results;
      state.myList = myList.results;      
      //console.log(state.myList);
      state.loaderEnabled = false;

      state.tableFilters = [
        { name: "Constructeur", key: "constructeur", label: "constructeur", },
        { name: "Type de produit", key: "fsh_product_family", label: "fsh_product_family", default: null },
      ];


    });

    onUpdated(async () => {
      if (state.applyFilterCode == 0 && router.currentRoute.value.params.code) {
        const typeProd = router.currentRoute.value.params.code as string;
        state.tableFilters = [
          { name: "Constructeur", key: "constructeur", label: "constructeur", },
          { name: "Type de produit", key: "fsh_product_family", label: "fsh_product_family", default: [typeProd == 'Gamme inconnue' ? '#NA' : typeProd] },
        ];
        state.applyFilterCode = 1;
      }
    });


    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      ExcelFormat,
      imgConst,
      affSUivant,
      affPrecedent,
      posIndex,
      refreshRouter,
      detailContrat,
      translateCommande,
    };
  },
});
</script>

<style>
.el-dropdown-menu__item:hover {
  cursor: pointer;
  background-color: #e4e7ed !important;
}
</style>